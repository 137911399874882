import groupSearchModule from '../groupSearchModule';

const friendsGroups = {
  templateUrl: 'friends-groups',
  bindings: {
    myGroups: '<',
    keyword: '<',
    showAll: '='
  },
  controller: 'friendsGroupsController'
};

groupSearchModule.component('friendsGroups', friendsGroups);

export default friendsGroups;
