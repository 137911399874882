import groupSearchModule from '../groupSearchModule';

const groupLandingRow = {
    templateUrl: "group-landing-row",
    bindings: {
        "keyword": "<",
        "search": "="
    },
    controller: "groupLandingRowController"
};

groupSearchModule.component("groupLandingRow", groupLandingRow);

export default groupLandingRow;
