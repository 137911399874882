import { authenticatedUser } from 'header-scripts';
import { CurrentUser, DeviceMeta } from 'Roblox';
import groupSearchModule from '../groupSearchModule';

function groupSearchController(
  $q,
  $scope,
  $location,
  $filter,
  cursorPaginationService,
  abpService,
  languageResource,
  groupsConstants,
  groupSearchConstants,
  groupsService,
  groupSearchService
) {
  'ngInject';

  $scope.resetGroups = function () {
    $scope.groups = [];
  };

  $scope.createGroupUrl = function () {
    return groupsConstants.absoluteUrls.createGroup;
  };

  $scope.myGroupsUrl = function () {
    return groupsConstants.absoluteUrls.myGroups;
  };

  $scope.showCreateGroupButton = function () {
    return CurrentUser.isAuthenticated && $scope.myGroups.length === 0;
  };

  $scope.showMyGroupsButton = function () {
    return CurrentUser.isAuthenticated && $scope.myGroups.length > 0;
  };

  $scope.setupAds = function () {
    if (!$scope.layout.adsInitialized) {
      $scope.layout.adsInitialized = true;
      abpService.registerAd(abpService.adIds.leaderboardAbp);
    }
  };

  $scope.adRefresh = function () {
    abpService.refreshAllAds();
  };

  function updateFilteredKeyword(filteredKeyword) {
    $scope.keyword.filtered = filteredKeyword;
    $scope.keyword.filteredHtml = `<span class="search-keyword">${$filter('escapeHtml')(
      filteredKeyword
    )}</span>`;
  }

  $scope.setKeyword = function (keyword) {
    $scope.pager.setPagingParameter('keyword', keyword);

    $location.path('/search/groups').search({ keyword });
    Object.assign($scope.keyword, {
      previous: keyword,
      current: keyword,
      urlKeyword: keyword,
      filtered: '',
      filteredHtml: ''
    });
  };

  $scope.pager = cursorPaginationService.createPager({
    pageSize: groupSearchConstants.pager.pageSize,
    loadPageSize: groupSearchConstants.pager.loadPageSize,

    getCacheKeyParameters(params) {
      return {
        keyword: params.keyword
      };
    },

    getRequestUrl() {
      return $filter('formatString')(groupsConstants.urls.searchGroups);
    },

    loadSuccess(groups) {
      $scope.layout.loadErrorMessage = null;
      $scope.groups = groups;
      updateFilteredKeyword($scope.keyword.previous);
      $scope.adRefresh();
    },

    loadError(error) {
      if (error && error.length > 0) {
        let message = error[0].userFacingMessage;
        switch (error[0].code) {
          case groupSearchConstants.errorCodes.default:
            message = languageResource.get(groupsConstants.translations.defaultError);
            break;
          case groupSearchConstants.errorCodes.textFiltered:
            $scope.keyword.current = $scope.keyword.current.replace(/[^\s]/g, '#');
            updateFilteredKeyword($scope.keyword.current);
            break;
        }
        $scope.layout.loadErrorMessage = message;
      }
      $scope.resetGroups();
    }
  });

  $scope.searchGroups = function (keyword, isNormalSearch) {
    $scope.setKeyword(keyword);
    $scope.pager.setPagingParameter('prioritizeExactMatch', isNormalSearch);

    if (keyword) {
      $scope.layout.showGroupLanding = false;
      $scope.pager.loadFirstPage();
    } else {
      // Show group landing page
      $scope.resetGroups();
      $scope.layout.showGroupLanding = true;
    }
  };

  $scope.loadGroups = () => {
    return $q((resolve, reject) => {
      if (!CurrentUser.isAuthenticated) {
        resolve();
      }

      groupSearchService.getGroups(authenticatedUser?.id).then(result => {
        $scope.myGroups = result.data;
        resolve(result);
      }, resolve);
    });
  };

  $scope.loadGroupMetadata = function () {
    return $q((resolve, reject) => {
      groupsService.getGroupMetadata().then(function (result) {
        $scope.metadata = result;
        resolve(result);
      }, resolve);
    });
  };

  function initVariables() {
    $scope.layout = {
      adsInitialized: false,
      showGroupLanding: false,
      showAllFriendsGroups: false,
      isPageLoadComplete: false,
      isPhone: DeviceMeta && DeviceMeta().isPhone
    };

    $scope.keyword = {
      current: $location.search().keyword,
      previous: '',
      urlKeyword: $location.search().keyword,
      filtered: '',
      filteredHtml: ''
    };

    $scope.groups = [];
    $scope.myGroups = [];
    $scope.metadata = {};
  }

  $scope.loadGroupSearch = function () {
    initVariables();
    $scope.setupAds();

    const metadataPromise = $scope.loadGroupMetadata();
    const membershipPromise = $scope.loadGroups();

    $q.all([membershipPromise, metadataPromise]).finally(() => {
      $scope.layout.isPageLoadComplete = true;
      $scope.searchGroups($scope.keyword.current, true);
    });
  };

  $scope.loadGroupSearch();
}

groupSearchModule.controller('groupSearchController', groupSearchController);
export default groupSearchController;
