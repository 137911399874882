import groupSearchModule from '../groupSearchModule';

const groupLanding = {
  templateUrl: 'group-landing',
  bindings: {
    myGroups: '<',
    search: '=',
    showAllFriendsGroups: '='
  },
  controller: 'groupLandingController'
};

groupSearchModule.component('groupLanding', groupLanding);

export default groupLanding;
