import groupSearchModule from '../groupSearchModule';

const groupResultCard = {
    templateUrl: "group-result-card",
    bindings: {
        "group": "<"
    },
    controller: "groupResultCardController"
};

groupSearchModule.component("groupResultCard", groupResultCard);

export default groupResultCard;
